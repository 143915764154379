<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="3" v-if="before"></b-col>
        <b-col cols="6">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-menu mr-2"></i>
              <strong>
                Caractéristiques du sol
                <span v-if="parcel">
                  ({{parcel.name}})
                </span>
              </strong>
            </div>
            <b-form>
              <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text><i class="icon-map mr-2"></i> Parcelle</b-input-group-text>
                </b-input-group-prepend>
                <b-form-select :value="null">
                  <template slot="first">
                    <option :value="null" disabled>-- Sélectionnez une parcelle --</option>
                  </template>
                  <option :value="1">Parcelle "Dschang"</option>
                  <option :value="2">Parcelle "Ebebda"</option>
                  <option :value="3">Parcelle "Nkolmitak"</option>
                </b-form-select>
                <b-form-invalid-feedback id="culture-feedback">
                  Veuillez selectionner une culture
                </b-form-invalid-feedback>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>Mois de l'an.</b-input-group-text>
                </b-input-group-prepend>
                <b-form-select :value="null">
                  <template slot="first">
                    <option :value="null" disabled>-- Sélectionnez le mois de l'année --</option>
                  </template>
                  <option :value="1">Janvier</option>
                  <option :value="2">Février</option>
                  <option :value="3">Mars</option>
                  <option :value="4">Avril</option>
                  <option :value="5">Mai</option>
                  <option :value="6">Juin</option>
                  <option :value="7">Juillet</option>
                  <option :value="8">Août</option>
                  <option :value="9">Septembre</option>
                  <option :value="10">Octobre</option>
                  <option :value="11">Novembre</option>
                  <option :value="12">Décembre</option>
                </b-form-select>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>Type de sol</b-input-group-text>
                </b-input-group-prepend>
                <b-form-select :value="null">
                  <template slot="first">
                    <option :value="null" disabled>-- Sélectionnez le type de sol --</option>
                  </template>
                  <option :value="1">Sol argileux</option>
                  <option :value="2">Sol limoneux</option>
                  <option :value="3">Sol sablonneux</option>
                </b-form-select>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>CEC</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder=""/>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>Taux de sat.</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder=""/>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>Rapport C/N</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder=""/>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>pH à l'eau</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder=""/>
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>pH au KCl</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder=""/>
              </b-input-group>

            </b-form>

            <div id="btns" class="text-center">
              <button-spinner variant="success" @click="onFake" class="ml-2" :fetching="fetchingFake">
                Voir les cultures recommandées
              </button-spinner>
            </div>
          </b-card>
        </b-col>

        <b-col cols="6" v-if="!before">
          <b-card header-tag="header" footer-tag="footer" class="map-card">
            <div slot="header">
              <i class="icon-map mr-2"></i><strong> Cultures recommandées</strong>
            </div>

            <c-table :table-data="recomms" :fields="fields" :actions="actions" :per-page=5 striped outlined fixed></c-table>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import Map from 'ol/Map.js';
  import Feature from 'ol/Feature.js';
  import {getArea} from 'ol/sphere.js';
  import View from 'ol/View.js';
  import {Polygon} from 'ol/geom.js';
  import {Draw, Modify} from 'ol/interaction';
  import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
  import {BingMaps, Vector as VectorSource} from 'ol/source.js';
  import {Fill, Stroke, Style} from 'ol/style.js';
  import {defaults as defaultControls, FullScreen, Control} from 'ol/control.js';
  import proj4 from 'proj4'

  import {Api, Regex, Toast, env, asset, str} from "../../../helpers/index";


  export default {
    name: "ExploitationCreateParcel",
    title: "PIA - Nouvelle parcelle",
    data() {
      return {
        before: true,
        fetchingFake: false,
        fields: [
          {key: 'name', label: 'Culture'},
          {key: 'yield', label: 'Rendement (modèle 2)', sortable: true},
          {key: 'percent', label: 'Pertinence (modèle 1)', sortable: true}
        ],
        actions: [],
        recomms: [
          {name: 'Pomme de terre', percent: '84.36%', yield: '19270.56 Kg/ha'},
          {name: 'Manioc', percent: '40.02%', yield: '14431.22 Kg/ha'},
          {name: 'Igname', percent: '66.24%', yield: '10823.32 Kg/ha'},
          {name: 'Macabo', percent: '60.94%', yield: '7468.16 Kg/ha'},
          {name: 'Patate douce', percent: '21.37%', yield: '5684.06 Kg/ha'},
          {name: 'Maïs', percent: '81.6%', yield: '1806.30 Kg/ha'},
        ],
        picture: null,
        name: '',
        cultureId: null,
        comment: '',
        submitted: false,
        area: '',
        map: null,
        vectorSource: null,
        drawInteraction: null,
        error: null,
        shapeType: 'Polygon',
        cultureOptions: [
          {value: "null", text: 'Aucune culture', selected: true, disabled: true}
        ],
        fetchingCreateParcel: false,
        parcel: null,
        fetchingParcel: false,
        csvFile: null,
        csvFileContent: null
      }
    },
    created() {
      this.getCultures();

      const edit = this.$route.query.edit
      if (edit && Regex.isPositiveNumber(edit)) {
        this.getParcel(edit)
      }
    },
    mounted() {
      this.loadFirst();
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      _picture() {
        return this.picture ? this.picture : env('DEFAULT_PARCEL_PICTURE')
      },
      csvTemplateUrl() {
        return env('PARCEL_CSV_TEMPLATE')
      },
      submitBtnText() {
        return this.parcel ? 'Enregistrer' : 'Valider'
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      areaState() {
        return !this.submitted || Regex.isNumber(this.area.replace(/ha/gi, '').trim()) ? null : false
      },
      csvFileState() {
        return this.csvFile == null || this.isValidCsv() ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      asset,
      isValidCsv() {
        let coordinates = null
        try {
          coordinates = this.csvFileContent
            .split(/[\s]/)
            .filter((item, index) => index > 0 && item.trim() !== '')
            .map(item => item.split(/[;,]/))
            .map(item => item.map(item => parseFloat(item)))

          for(let i=0; i<coordinates.length; i++)
          {
            if(
              coordinates[i].length !== 2
                || coordinates[i][0] < -90
                || coordinates[i][0] > 90
                || coordinates[i][1] < -180
                || coordinates[i][1] > 180
            )
              return null
          }
        }
        catch (e) {
          return null
        }

        return coordinates
      },
      renderMapFromCsv() {
        let coordinates = this.isValidCsv()

        if(!coordinates)
          return

        if(this.vectorSource.getFeatures().length)
          this.deletePolygon()

        coordinates = [
          coordinates.map(item => proj4('EPSG:4326', 'EPSG:3857', item))
        ]

        let polygon = new Polygon(coordinates);
        let feature = new Feature(polygon)
        this.vectorSource.addFeature(feature);

        this.formatPolygonArea(polygon)

        feature.getGeometry().on('change', this.onChangeArea)

        this.map.setView(new View({
          center: feature.getGeometry().getInteriorPoint().getCoordinates(),
          zoom: 15
        }))

        this.map.addInteraction(new Modify({
          source: this.vectorSource
        }))
      },
      csvFileChanged(e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length)
          return;

        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.csvFileContent = e.target.result;
          this.renderMapFromCsv()
        };
        reader.readAsText(files[0]);
      },
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.picture = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      },
      formatPolygonArea(polygon) {
        let area = getArea(polygon);
        let output;
        if (area > 10000) {
          // (Math.round(area / 1000000 * 100) / 100)
          output = +((area / 1000000) * 100).toFixed(4) +
            ' ' + 'ha';
        } else {
          // (Math.round(area * 100) / 100)
          output = (area / 10000).toFixed(4) +
            ' ' + 'ha';
        }
        this.area = output
        // // return output;
      },
      onChangeArea(event) {
        let geom = event.target;
        this.formatPolygonArea(geom)
      },
      loadFirst() {
        let raster = new TileLayer({
          source: new BingMaps({
            imagerySet: 'AerialWithLabels',
            key: env('BING_MAPS_KEY')
          })
        });

        let source = new VectorSource();

        let vector = new VectorLayer({
          source: source,
          style: new Style({
            fill: new Fill({
              color: 'rgba(51,144,250,0.44)'
            }),
            stroke: new Stroke({
              color: '#0711ff',
              width: 3
            })
          })
        });

        const _this = this
        let DrawControl = (function (Control) {
          function DrawControl(opt_options) {
            let options = opt_options || {};

            let button = _this.$refs.drawButton
            let element = _this.$refs.drawDiv
            element.appendChild(button);

            Control.call(this, {
              element: element,
              target: options.target
            });

            button.addEventListener('click', this.handleDraw.bind(_this), false);
          }

          if (Control) DrawControl.__proto__ = Control;
          DrawControl.prototype = Object.create(Control && Control.prototype);
          DrawControl.prototype.constructor = DrawControl;

          DrawControl.prototype.handleDraw = _this.addDrawInteraction

          return DrawControl;
        }(Control));

        let DeleteFeatureControl = (function (Control) {
          function DeleteFeatureControl(opt_options) {
            let options = opt_options || {};

            let button = _this.$refs.deleteFeatureButton
            let element = _this.$refs.deleteFeatureDiv
            element.appendChild(button);

            Control.call(this, {
              element: element,
              target: options.target
            });

            button.addEventListener('click', this.handleDeleteFeature.bind(_this), false);
          }

          if (Control) DeleteFeatureControl.__proto__ = Control;
          DeleteFeatureControl.prototype = Object.create(Control && Control.prototype);
          DeleteFeatureControl.prototype.constructor = DeleteFeatureControl;

          DeleteFeatureControl.prototype.handleDeleteFeature = _this.deletePolygon

          return DeleteFeatureControl;
        }(Control));

        let map = new Map({
          layers: [raster, vector],
          controls: defaultControls({attribution: false}).extend([
            new FullScreen(),
            new DeleteFeatureControl(),
            new DrawControl(),
          ]),
          target: 'map',
          view: new View({
            center: [-11000000, 4600000],
            zoom: 4
          })
        });

        let draw = new Draw({
          source: source,
          type: this.shapeType
        });

        draw.setActive(false)

        this.map = map;
        this.vectorSource = source;
        this.drawInteraction = draw;

      },
      getCultures() {
        Api.get('/exploitation/culture/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.cultureOptions = res.data.data.map(culture => ({
                value: culture.id,
                text: culture.name
              }))
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            // this.fetchingCultureList = false
          })
      },
      renderMap() {
        let polygon = new Polygon(this.parcel.feature.coordinates);
        // polygon.transform('EPSG:4326', 'EPSG:3857');
        let feature = new Feature(polygon)
        this.vectorSource.addFeature(feature);

        feature.getGeometry().on('change', this.onChangeArea)

        this.map.setView(new View({
          center: this.parcel.feature.center,
          zoom: this.parcel.feature.zoom
        }))

        this.map.addInteraction(new Modify({
          source: this.vectorSource
        }))
      },
      getParcel(parcelId) {
        this.fetchingParcel = true
        Api.get('/exploitation/parcel', {
          exploitationId: this.exploitationId,
          parcelId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.parcel = res.data.data

              this.picture = this.asset(this.parcel.picture)
              this.name = this.parcel.name
              this.area = this.parcel.area + ' ha'
              this.cultureId = this.parcel.cultureId
              this.comment = this.parcel.comment

              this.renderMap()

            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingParcel = false
          })
      },
      drawStart_End() {
        let listener;
        let modify;
        let sketch;

        let formatPolygonArea = function (polygon) {
          let area = getArea(polygon);
          let output;
          if (area > 10000) {
            // (Math.round(area / 1000000 * 100) / 100)
            output = +((area / 1000000) * 100).toFixed(4) +
              ' ' + 'ha';
          } else {
            // (Math.round(area * 100) / 100)
            output = (area / 10000).toFixed(4) +
              ' ' + 'ha';
          }
          return output;
        };

        let circleArea = (radius) => {
          return ((Math.PI * radius * radius) / 10000).toFixed(4) +
            ' ' + 'ha';
        }
        const _this = this

        this.drawInteraction.on('drawstart',
          function (evt) {
            sketch = evt.feature;
            listener = sketch.getGeometry().on('change', _this.onChangeArea)
          });

        this.drawInteraction.on('drawend',
          function () {
            modify = new Modify({
              source: _this.vectorSource
            });
            _this.map.removeInteraction(_this.drawInteraction);
            _this.map.addInteraction(modify);
          });
      },
      addDrawInteraction() {
        if (!this.drawInteraction.getActive()) {
          this.drawInteraction.setActive(true);
          this.map.addInteraction(this.drawInteraction);
          this.drawStart_End();
        }
      },
      deletePolygon() {
        this.vectorSource.clear();
        this.area = '';
        this.drawInteraction.setActive(false)
        this.csvFile = null

        this.addDrawInteraction()
      },
      redirect() {
        this.$router.push({
          name: 'ExploitationParcels',
          params: {id: this.exploitationId}
        })
      },
      valid() {
        return this.name.trim().length >= 3 &&
          Regex.isNullOrPositiveNumber(this.cultureId) &&
          Regex.isNumber(this.area.replace(/ha/gi, '').trim())
      },
      onSubmit() {
        return this.parcel ? this.updateParcel() : this.createParcel()
      },
      onCancel() {
        return this.redirect()
      },
      createParcel() {
        this.submitted = true;
        if (!this.valid()) return

        this.fetchingCreateParcel = true

        let feature = {
          type: this.vectorSource.getFeatures()[0].getGeometry().getType(),
          center: this.vectorSource.getFeatures()[0].getGeometry().getInteriorPoint().getCoordinates(),
          zoom: this.map.getView().getZoom(),
          coordinates: this.vectorSource.getFeatures()[0].getGeometry().getCoordinates()
        };

        Api.post('/exploitation/parcel/create', {
          picture: this.picture,
          name: this.name,
          cultureId: this.cultureId,
          exploitationId: this.exploitationId,
          comment: this.comment,
          area: parseFloat(this.area.replace('ha', '').trim()),
          feature: feature
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Parcelle créée avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateParcel = false
          })
      },
      updateParcel() {
        this.submitted = true;
        if (!this.valid()) return

        this.fetchingCreateParcel = true

        let feature = {
          type: this.vectorSource.getFeatures()[0].getGeometry().getType(),
          center: this.vectorSource.getFeatures()[0].getGeometry().getInteriorPoint().getCoordinates(),
          zoom: this.map.getView().getZoom(),
          coordinates: this.vectorSource.getFeatures()[0].getGeometry().getCoordinates()
        };

        Api.post('/exploitation/parcel/update', {
          parcelId: this.parcel.id,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          name: this.name,
          cultureId: this.cultureId,
          exploitationId: this.exploitationId,
          comment: this.comment,
          area: parseFloat(this.area.replace('ha', '').trim()),
          feature: feature
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Parcelle mise à jour avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateParcel = false
          })
      },
      deleteParcel() {
        const res = confirm(`Etes-vous sûr de vouloir supprimer cette parcelle ?`)
        if (res) {
          Api.post('/exploitation/parcel/delete', {
            exploitationId: this.exploitationId,
            parcelId: this.parcel.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                Toast.success('Parcelle supprimée avec succès !')
                this.redirect()
              } else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingCreateParcel = false
            })
        }
      },
      onFake() {
        this.fetchingFake = true
        const _this = this
        setTimeout(() => {
          _this.fetchingFake = false
          _this.before = false
        }, 2500)
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import "../../../../node_modules/ol/ol.css"
  #map {
    width: 100%;
    height: 480px;
  }

  .draw-control {
    top: 50px;
    right: .5em;
  }

  .delete-control {
    top: 76px;
    right: .5em;
  }

  .map-card {
    /*min-height: 600px;*/
  }

  .input-group-prepend {
    width: 100px;
  }

  .input-group-prepend .input-group-text {
    width: 100%;
  }
</style>
